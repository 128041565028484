@import './colors.scss';
@import './fonts.scss';
@import './settings.scss';

$url: '../../assets/';

@mixin icon-image($image-name, $old-support: false, $url: $url, $ext: '.png') {
  @if $old-support == false {
    background: url($url + $image-name + $ext) 0 0 no-repeat;
  } @else if $old-support == true {
    background-image: url($url + $image-name + $ext);
    background: url($url + $image-name + '.svg'), linear-gradient(transparent, transparent);
    background-repeat: no-repeat;
  }
}

@mixin background-position-size($background-position: center, $background-size: cover) {
  background-position: $background-position !important;
  background-size: $background-size !important;
}

@mixin background-image($image-name, $url: $url, $ext: '.jpg', $fixed: true) {
  background: url($url + $image-name + $ext) 0 0 no-repeat;
  @include background-position-size();

  @if $fixed == true {
    background-attachment: fixed;
  }
}

@mixin width-height($width, $height) {
  width: $width;
  height: $height;
}

@mixin width-height-same($width) {
  @include width-height($width, $width);
}

@mixin responsive-width($desktop-width, $mobile-width) {
  width: $desktop-width;

  @media (max-width: $max-mobile-width-sm) {
    width: $mobile-width;
  }
}

@mixin responsive-inline-block($desktop-width) {
  @include responsive-width($desktop-width, 100%);
  display: inline-block;

  @media (max-width: $max-mobile-width-sm) {
    display: block;
  }
}

@mixin margin($margin-top: 0, $margin-right: 0, $margin-bottom: 0, $margin-left: 0) {
  margin-top: $margin-top;
  margin-right: $margin-right;
  margin-bottom: $margin-bottom;
  margin-left: $margin-left;
}

@mixin margin-one-var($margin: 0) {
  @include margin($margin, $margin, $margin, $margin);
}

@mixin margin-two-var($margin-top-bottom: 0, $margin-left-right: 0) {
  @include margin($margin-top-bottom, $margin-left-right, $margin-top-bottom, $margin-left-right);
}

@mixin padding($padding-top: 0, $padding-right: 0, $padding-bottom: 0, $padding-left: 0) {
  padding-top: $padding-top;
  padding-right: $padding-right;
  padding-bottom: $padding-bottom;
  padding-left: $padding-left;
}

@mixin padding-one-var($padding: 0) {
  @include padding($padding, $padding, $padding, $padding);
}

@mixin padding-two-var($padding-top-bottom: 0, $padding-left-right: 0) {
  @include padding($padding-top-bottom, $padding-left-right, $padding-top-bottom, $padding-left-right);
}

@mixin menu-item-icon($image-name, $background-size, $url) {
  @include icon-image($image-name, true, $url);
  @include background-position-size(center, $background-size);
  display: inline-block;
  vertical-align: middle;
}

@mixin link($color, $hover-color, $text-decoration: none) {
  text-decoration: $text-decoration;
  color: $color;

  &:hover {
    color: $hover-color;
  }
}

@mixin button($background-color, $border: none) {
  @include padding-two-var(15px);
  @include link($white, $white);
  display: inline-block;
  background-color: $background-color;
  text-transform: uppercase;
  text-align: center;
  border: $border;
  border-radius: $button-border-radius;
}

@mixin purple-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($pink-2);
  background-image: $purple-button-gradient;
}

@mixin purple-button-reverse($font-size: 12px) {
  @include font-bold($font-size);
  @include button($pink-2);
  background-image: $purple-button-gradient-reverse;
}

@mixin purple-button-border($font-size: 12px){
  @include font-bold($font-size);
  @include button($pink-2);
  background: linear-gradient($dark-purple-1, $dark-purple-1) padding-box,
  $purple-button-gradient border-box;
  border-radius: 50em;
  border: 1.5px solid transparent;
}

@mixin red-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($pink-2);
  background-image: $red-button-gradient;
}

@mixin orange-button-border($font-size: 12px){
  @include font-bold($font-size);
  @include button($orange-6);
  background: linear-gradient($dark-purple-1, $dark-purple-1) padding-box,
  $orange-button-gradient border-box;
  border-radius: 50em;
  border: 1.5px solid transparent;
}

@mixin white-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($white);
}

@mixin teal-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($teal);
}

@mixin pink-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($pink-3);
  background-image: $pink-button-gradient;
}

@mixin grey-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($grey-2);
}

@mixin grey-gradient-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($grey-2);
  background: $free-spin-button-gradient;
}

@mixin dark-grey-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($dark-grey-1);
}

@mixin blue-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($dark-blue-2);
}

@mixin orange-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($orange-2);
  background-image: $orange-button-gradient;
}

@mixin purple-blue-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($teal-1);
  background-image: $free-spin-gradient;
}

@mixin orange-button-border($font-size: 12px){
  @include font-bold($font-size);
  @include button($pink-2);
  background: transparent;
  border-radius: 50em;
  border: 2px solid $yellow-5;
}

@mixin transparent-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button(transparent, $white-border);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@mixin discord-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($discord);
}

@mixin telegram-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($telegram);
}

@mixin refer-a-friend-button($font-size: 12px) {
  @include font-bold($font-size);
  @include button($orange-5);
  background-image: $refer-a-friend-button-gradient;
}

@mixin grid() {
  margin-bottom: -15px;

  &-section {
    @include padding-one-var();
    border-top: none;
  }

  &-item {
    text-align: left;
    padding-bottom: 10px;

    &.nft {
      padding-bottom: 0px;
    }

    &-link {
      @include link($white, $white);
    }

    &-image {
      @include margin-one-var();
      @include width-height(100%, 0);
      @include background-position-size();
      border-radius: $border-radius-mobile;
      padding-top: 100%;
      position: relative;
      z-index: 1;

      &-container {
        position: relative;
      }
    }

    &-info {
      @include padding-two-var(15px, 0);
      text-align: center;
    }

    &-name {
      @include font-medium(14px);
    }
  }
}

@mixin chat-profile-pic($size) {
  @include width-height-same($size);
  @include background-position-size(center, $size);
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

@mixin flex-page($justify-content) {
  display: flex;
  align-items: center;
  justify-content: $justify-content;
  flex-direction: column;
  transition: ease-in-out 0.3s 0.3s;
  position: relative;
}

@mixin progress-bar($background-color: $progress-bar-bg) {
  height: 5px;
  border-radius: 0;
  background-color: $background-color;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin flex($justify-content, $align-center, $flex-column) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-center;
  flex-direction: $flex-column;
}

@mixin border-top-side($top-left, $top-right) {
  border-top-left-radius: $top-left;
  border-top-right-radius: $top-right;
}

@mixin position-top-right($position, $top, $right) {
  position: $position;
  top: $top;
  right: $right;
}

@mixin position-top-left($position, $top, $left) {
  position: $position;
  top: $top;
  left: $left;
}

@mixin position-bottom-right($position, $bottom, $right) {
  position: $position;
  bottom: $bottom;
  right: $right;
}

@mixin position-bottom-left($position, $bottom, $left) {
  position: $position;
  bottom: $bottom;
  left: $left;
}

@mixin position-float-top-right($top, $right) {
  @include position-top-right(absolute, $top, $right);
}

@mixin position-float-top-left($top, $left) {
  @include position-top-left(absolute, $top, $left);
}

@mixin position-float-bottom-right($bottom, $right) {
  @include position-bottom-right(absolute, $bottom, $right);
}

@mixin position-float-bottom-left($bottom, $left) {
  @include position-bottom-left(absolute, $bottom, $left);
}

@mixin wen-lambo-thumbnail-border($margin) {
  @include position-float-top-left(0, 0);
  @include margin-one-var($margin);
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: inherit;
  background: $wen-lambo-border-gradient;
  content: '';
}

@mixin game-pass-recommended-badge($top, $right, $background: $wen-lambo-banner-gradient) {
  @include position-float-top-right($top, $right);
  @include font-bold();
  @include padding-two-var(2px, 6px);
  background: $background;
  text-transform: uppercase;
  border-top-right-radius: $border-radius-mobile;
  border-bottom-left-radius: $border-radius-mobile;
}

@mixin launchpad-item-banner($banner-url, $desktop-height, $mobile-height) {
  @include icon-image('banner', false, $banner-url);
  @include width-height(100%, $desktop-height);
  @include background-position-size(center, contain);
  border-radius: $border-radius-mobile;

  @media (max-width: $max-mobile-width-sm) {
    @include width-height(100%, $mobile-height);
  }
}

@mixin grid-two-banners($unit, $mobile-unit, $gap) {
  display: grid;
  grid-template-columns: $unit;
  grid-gap: $gap;

  @media (max-width: $max-mobile-width-sm) {
    grid-template-columns: $mobile-unit;
  }
}