@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.buy-reward-modal {
  @include margin-one-var(20px);
  @include flex(center, center, column);
  width: 100%;
  border: none;
  color: $white;
  background-color: $dark-blue-5;
  border-radius: $border-radius-mobile;
  max-width: 400px;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: $border-radius-desktop; 
    padding: 1px; 
    background:linear-gradient(45deg, $red-4, $pink-1); 
    mask: linear-gradient($white 0 0) content-box, linear-gradient($white 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude; 
  }
  
  &-container {
    @include width-height(100%, 100%);
    overflow: hidden;
    z-index: 1000;

    @media (max-width: $max-mobile-width-sm) {
      @include width-height-same(100%);
    }

    &.fade .modal-dialog {
      transform: translateY(10vh);
    }

    &.show .modal-dialog {
      transform: translateY(0);
    }
  }

  &-bg {
    z-index: 1000;
    
    &.show {
      opacity: 0.6;
    }
  }

  &-dialog {
    @include margin-one-var();
    max-width: none !important;
    min-height: 100%;
    justify-content: center;
  }

  &-image {
    @include width-height-same(100%);
    border-radius: $border-radius-mobile;
  }

  &-body {
    @include flex(center, center, column);
    @include padding-one-var(30px);
    text-align: center;
    gap: 20px;
  }

  &-title {
    @include font-semi(18px);
    @include flex(center, center, column);

    &-success {
      margin-top: 20px;
    }
  }

  &-item {
    @include font-bolder(24px);
  }

  &-description {
    @include font-semi(16px);
    margin-top: 10px;
    max-width: 280px;
  }

  &-button {
    @include purple-button();
    @include padding-one-var(8px);
    width: 80px;

    &-solo {
      @include purple-button();
      @include padding-one-var(8px);
      width: 144px;
      position: relative;
      z-index: 10;
    }

    &-container {
      @include flex(center, center, row);
      gap: 14px;
      margin-top: 10px;
    }
  }

  &-confetti {
    @include position-float-top-left(0, 0);
    @include width-height-same(100%);
    z-index: 1;
  }
}