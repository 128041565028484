@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.tags-component {
  &-tooltip {
    width: auto;
    max-width: 400px;
    margin-top: 5px;

    &-inner {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      background-color: $dark-purple-2-translucent !important;
    }
  }

  &-tags {
    display: block;
    text-align: left;
    margin-bottom: -5px;
  }

  &-tag {
    @include padding-two-var(1px, 5px);
    @include font-medium(10px);
    @extend %text-ellipsis;
    display: inline-block;
    width: auto;
    max-width: calc(100vw - 60px);
    vertical-align: top;
    text-transform: uppercase;
    color: $purple-6;
    background-color: $dark-purple-3;
    border: 1px solid $purple-6;
    border-radius: 2px;
    margin-right: 5px;
    margin-bottom: 5px;

    @media (max-width: $max-button-width-mobile) {
      max-width: calc(100vw - 190px);
    }

    &:last-child {
      margin-right: 0;
    }

    &-fluid {
      max-width: none;
    }

    &-more {
      @include padding-two-var(2px);
      border: none;
      background-color: transparent;
    }
  }
}