@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.gallery {
  @include margin-two-var(-10px, -10px);

  &-scroll {
    .slick-prev, .slick-next {
      &:before {
        margin-top: 0;
      }
    }
  }

  &-section {
    border-top: none;
  }
}

.screenshot {
  @extend %gallery;
  @include padding-one-var(10px);
  position: relative;
  border: none;
  outline: none;

  &-image {
    @include margin-one-var();
    @include background-position-size();
    width: 100%;
    padding-top: 60%;
    border-radius: $border-radius-mobile;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &-game {
      padding-top: 158%;
    }
  }
}

.game {
  &-inner {
    .featured-section {
      @media (max-width: $max-mobile-width-sm) {
        margin-top: 20px;
      }
  
      &-fluid {
        @media (max-width: $max-mobile-width-sm) {
          margin-top: 20px;
        }
      }
    }
  }

  &-sections {
    border-top: $dark-blue-border;
  }

  &-mechanics {
    @include font-light(14px);

    @media (max-width: $max-tablet-width) {
      @include font-light();
    }

    &-section {
      border-top: none;
      padding-bottom: 0;
    }
  }

  &-info {
    @include padding-one-var(15px);
    background-color: $dark-purple-1-translucent;
    border-radius: 10px;

    &-table {
      @media (min-width: $min-tablet-width) {
        margin-bottom: -10px;
      }

      &-cell {
        @media (min-width: $min-tablet-width) {
          margin-bottom: 10px;
        }
      }
    }

    &-label {
      @include font-semi(14px);

      @media (max-width: $max-mobile-width-sm) {
        display: inline-block;
        vertical-align: top;
        width: 35%;
      }
    }

    &-value {
      @include font-light(14px);

      @media (max-width: $max-mobile-width-sm) {
        display: inline-block;
        vertical-align: top;
        width: 65%;
        text-align: right;
      }
    }
  }
}

.f2p-game {
  &-buttons {
    @include margin-two-var(30px);
    @include responsive-inline-block(200px);
    vertical-align: top;
    text-align: center;

    @media (max-width: $max-mobile-width-sm) {
      margin-top: 20px;
    }
  }

  &-button {
    @include purple-button();
    @include responsive-width(100%, 100%);
    max-width: none;

    @media (max-width: $max-mobile-width-sm) {
      max-width: $max-button-width-mobile;
    }
  }
}