@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles//settings.scss';


.desktop-modal {
  background-color: $dark-purple-1;
  background-size: cover;
  background-position: center;
  border: 1.5px solid $purple-1;
  border-radius: 15px;
  color: $white;
  text-align: center;
  position: relative;

  @media (max-width: $max-mobile-width-sm) {
    border: none;
  }

  &-half {
    background-color: $dark-blue-7;
  }

  &-indicator {
    @include width-height(70px, 5px);
    @include position-float-top-left(-20px, 50%);
    background-color: $white;
    border-radius: 4px;
    transform: translateX(-50%);
  }

  &-mobile {
    @media (max-width: $max-mobile-width-sm) {
      height: 100%;
    }

    &-list {
      background-color: transparent;
    }
  }

  & > .modal-body {
    @media (max-width: $max-mobile-width-sm) {
      padding: 0;
    }
  }

  &-container {
    z-index: 1000;

    @media (max-width: $max-mobile-width-sm) {
      padding: 0 !important;
      overflow: hidden;
    }

    &-front {
      z-index: 2000;

      @media (max-width: $max-mobile-width-sm) {
        padding: 0 !important;
      }
    }
 
    &.fade .modal-dialog {
      transform: scale(1.05);

      @media (max-width: $max-mobile-width-sm) {
        transform: translate(0, 10vh);
      }
    }

    &.show .modal-dialog {
      transform: scale(1);

      @media (max-width: $max-mobile-width-sm) {
        transform: translate(0, 0);
      }
    }
  }

  &-dialog {
    @include flex(center, center, row);
    max-width: 760px;

    @media (max-width: $max-mobile-width-sm) {
      @include margin-one-var();
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      align-items: flex-end;
      max-width: none;
      margin: 0;
    }
  }

  &-bg {
    background-color: $dark-purple-2;
    z-index: 1000;
    &.show {
      opacity: 0.9;
    }
  }
}