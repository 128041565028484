@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$gamepass-url: '../../assets/gamepass/';
$icons-url: '../../assets/icons/';

.transactions-details {
  border: none;
  padding: 0px;
  color: $white;

  &-section > h1 {
    @include font-semi(20px);

    @media (max-width: $max-mobile-width-sm) {
      @include font-semi(18px);
    }
  }

  &-products {
    @include padding(10px);

    &-container {
      @include flex(center, center, column);
      @include padding-two-var(25px);
      gap: 20px;

      @media (max-width: $max-mobile-width-sm) {
        @include padding-one-var(20px);
      }
    }

    &-body {
      @media (max-width: $max-mobile-width-sm) {
        background-color: $dark-purple-7;
        margin-bottom: 10px;
        border-radius: 8px;
      }
    }

    &-footer {
      @media (max-width: $max-mobile-width-sm) {
        @include padding-one-var(20px);
        background-color: $dark-purple-7;
        border-radius: 8px;
      }
    }

    &-header {
      @include flex(space-between, center, row);

      @media (max-width: $max-mobile-width-sm) {
        @include padding(20px, 20px, 10px, 20px);
      }
    }

    &-name {
      @include font-semi(14px);
    }

    &-title {
      @include font(16px);
      padding: 0;
      text-align: left;

      @media (max-width: $max-mobile-width-sm) {
        @include font(14px);
      }

      &-value {
        @include font-semi(16px);
        padding: 0;
        text-align: right;

        @media (max-width: $max-mobile-width-sm) {
          @include font-semi(14px);
          word-break: break-word;
        }
      }
    }

    &-total {
      @include font-semi(18px);
    }

    &-wrapper {
      @include flex(space-between, center, row);
      @include padding(5px, 0, 20px, 0);
      border-bottom: $grey-line-border;

      @media (max-width: $max-mobile-width-sm) {
        @include padding(10px, 20px, 20px, 20px);
        border: none;
      }

      &-footer {
        @include flex(space-between, center, column);
        @include padding(15px);
        gap: 15px;

        @media (max-width: $max-mobile-width-sm) {
          @include padding();
        }
      }
    }

    &-quantity {
      @include font(14px);
      color: $light-purple;
    }

    &-amount {
      @include font(14px);
    }

    &-image {
      max-width: 43px;
    }

    &-transaction {
      @include font(14px);
    }

    &-status {
      @include font-semi(14px !important);
    }

    &-items {
      @include flex(flex-start, center, row);
      gap: 10px;
      width: 100%;
    }
  }

  &-mgc {
    @include icon-image('mgo-coin', true, $icons-url);
    @include width-height-same(30px);
    @include background-position-size(center, cover);
  }

  &-gold {
    @include icon-image('coin', true, $gamepass-url);
    @include width-height-same(30px);
    @include background-position-size(center, cover);
  }

  &-header {
    @include flex(center, center, column);
    @include padding-one-var(15px);
    color: $white;
    background-color: $dark-purple-3;
    border-radius: 8px;
    margin-bottom: 40px;
    gap: 10px;

    @media (max-width: $max-mobile-width-sm) {
      background-color: $dark-purple-7;
      margin-bottom: 10px;
    }

    &-amount {
      @include font-semi(36px);

      &-failed {
        color: $grey-1;
      }
    }

    &-symbol {
      @include font-semi(35px);

      &-failed {
        color: $grey-1;
      }
    }

    &-status {
      @include font-semi(18px);
      text-transform: uppercase;
      color: $green-1;

      @media (max-width: $max-mobile-width-sm){
        @include font-semi(14px);
      }

      &-success {
        color: $green-1;
      }

      &-failed {
        color: $red-1;
      }

      &-pending {
        color: $orange-1;
      }

      &-reason {
        @include font-medium(15px);
        color: $red-1;

        @media (max-width: $max-mobile-width-sm){
          @include font-medium(13px);
        }
      }
    }

    &-date {
      @include font(14px);

      @media (max-width: $max-mobile-width-sm){
        @include font(12px);
      }
    }

    &-line {
      @include font(14px);

      @media (max-width: $max-mobile-width-sm){
        @include font(12px);
      }
    }

    &-time {
      @include font(14px);

      @media (max-width: $max-mobile-width-sm){
        @include font(12px);
      }
    }
  }

  &-body {
    @include flex(center, center, column);
    gap: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid $dark-blue-1;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var(20px);
      background-color: $dark-purple-7;
      margin-bottom: 10px;
      border-bottom: none;
      border-radius: 8px;
    }

    &-button {
      @include flex(flex-end, center, row);
      @include font(14px);
      color: $pink-1;
      gap: 0px;
      cursor: pointer;
      margin-right: -7px;

      @media (max-width: $max-mobile-width-sm){
        @include font(12px);
      }
    }

    &-arrow {
      height: 15px !important;
    }

    &-title {
      @include font(16px);
      padding: 0;

      @media (max-width: $max-mobile-width-sm){
        @include font(14px);
      }
    }

    &-subtitle {
      @include font-semi(16px);
      text-align: right;
      padding: 0;
      word-wrap: break-word;

      @media (max-width: $max-mobile-width-sm){
        @include font-semi(14px);
      }
    }
  }

  &-footer {
    @include flex(center, center, column);
    padding-top: 20px;
    gap: 15px;
    border-top: 1px solid $dark-blue-1;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var(20px);
      background-color: $dark-purple-7;
      border-top: none;
      border-radius: 8px;
    }
  }

  &-info {
    @include flex(center, center, column);
    @include padding-two-var(20px);
    gap: 15px;
    padding-top: 20px;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var(20px);
      background-color: $dark-purple-7;
      border-top: none;
      border-radius: 8px;
      margin-bottom: 10px;
    }

    &-title {
      @include font(14px);

      @media (max-width: $max-mobile-width-sm){
        @include font(12px);
      }
    }

    &-subtitle {
      @include font-semi(14px);
      text-align: right;
      width: fit-content;

      @media (max-width: $max-mobile-width-sm){
        @include font-semi(12px);
      }
    }
  }


}