@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$transactions-url: '../../../assets/transactions/';
$icons-url: '../../../assets/icons/';

.wallet-credits {
  &-wrapper {
    @include icon-image('bg', false, $transactions-url);
    @include background-position-size(center, cover);
    @include padding-two-var(30px, 20px);
    color: $white;
    background-color: $dark-purple-3;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var(15px);
    }
  }

  &-inner {
    @include flex(center, center, column);
    text-align: center;
  }

  &-credits {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }

  &-title {
    @include font-semi(18px);
    @include margin-one-var(0);
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 120px);
    padding-bottom: 10px;
  }

  &-subtitle {
    @include font(14px);
  }

  &-topup {
    @include flex(center, center, row);
    width: 100%;
    gap: 10px;

    &-button {
      @include white-button();
      @include link($purple-1, $purple-1);
      @include padding-two-var(8px, 15px);
      width: 100%;
      max-width: 160px;
      position: relative;

      &-coming-soon {
        @include position-float-top-right(0, 0);
        @include padding-two-var(2px, 8px);
        @include font-bold(10px);
        color: $white;
        background-color: $pink-6;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
  }

  &-icon {
    @include icon-image('mgo-coin', true, $icons-url);
    @include background-position-size(center, cover);
    @include width-height-same(30px);

    @media (max-width: $max-mobile-width-sm) {
      @include width-height-same(30px);
    }
  }

  &-country {
    @include font-semi(20px);
    @include width-height(20px, 15px);
  }

  &-text {
    @include flex(center, center, row);
    @include font-medium(14px);
    vertical-align: top;
    width: 100%;
    gap: 10px;
    margin-bottom: 0px;

    &-value {
      @include flex(center, center, row);
      @include font-medium(14px);
      vertical-align: top;
      width: 100%;
      gap: 10px;
      margin-bottom: 15px;
    }
  }

  &-amount {
    @include font-semi(24px);
    color: $white;

    &-value {
      @include font(14px);
      color: $white;
    }
  }
}