@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.withdrawal-methods {
  &-section {
    border-top: none;
    @include padding(0, 0, 20px);
  
    @media (max-width: $max-mobile-width-sm) {
      @include padding(0, 0, 10px);
    }
  }

  &-content {
    margin-top: 30px;
  }

  &-box {
    @include padding-two-var(12px, 16px);
    width: 100%;
    background-color: $dark-purple-7;
    border-radius: $border-radius-mobile;
    position: relative;
    cursor: pointer;

    &-image {
      width: 38px;
      object-fit: contain;
      margin-bottom: 2px;
    }

    &-name {
      @include font-semi(16px);
    }

    &-wrapper {
      @include flex(flex-start, center, row);
      gap: 10px;
    }

    &-arrow {
      @include position-float-top-right(50%, 8px);
      @include width-height-same(30px !important);
      transform: translateY(-50%);
    }
  }
}