@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles//settings.scss'; 

.gold-balance {
  @include red-button();
  @include flex(center, center, row);
  @include padding(6px, 10px, 6px, 12px);
  border: $white-border;
  width: 100%;
  max-width: 200px;
  margin-top: 50px;

  @media (max-width: $max-mobile-width-sm) {
    @include margin(0, 0, 20px, 20px);
    width: 44%;
  }

  &-icon {
    @include width-height-same(36px);

    @media (max-width: $max-mobile-width-sm) {
      @include width-height-same(34px);
    }
  }

  &-container {
    @include flex(flex-start, center, row);
    width: 100%;
  }

  &-wrapper {
    @include flex(center, center, column);
    width: 100%;

    &-gold {
      @include font-bolder(16px);

      @media (max-width: $max-mobile-width-sm) {
        @include font-bolder(14px);
      }
    }

    &-text {
      @include font-semi(14px);

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(12px);
      }
    }
  }
}