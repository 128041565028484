@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/extends.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

$transactions-url: '../../../assets/transactions/';
$launchpad-url: '../../../assets/launchpad/';
$tournaments-url: '../../../assets/tournaments/';
$icons-url: '../../../assets/icons/';

.section {
  @include padding-two-var(30px);
  border-top: $dark-blue-border;

  &:last-child {
    padding-bottom: 0;
  }

  &-cart {
    @include position-float-top-right(0, 0);
    @include flex(center, center, row);
    cursor: pointer;

    &-items {
      @include position-float-top-right(0, -16px);
    }

    &-image {
      @include width-height(24px, 22px);
      @include icon-image('gamepass-cart', true, $icons-url);
      @include background-position-size();
    }

    &-count {
      @include width-height-same(fit-content);
      @include padding-two-var(0px, 5px);
      transform: translate(-12px, -9px);
      background-color: $pink-6;
      border-radius: 2.75rem;
      border: $cart-dot-border;

      &-text {
        @include font-semi();
      }
    }
  }

  &-header {
    position: relative;

    &-sticky {
      @media (max-width: $max-mobile-width-sm){
        @include position-top-left(sticky, -1px, 0);
        @include padding(30px, 0px, 20px, 0px);
        background-color: $dark-purple-1;
        z-index: 1;
      }
    }
  }

  &-filter {
    position: absolute;
    top: -5px;
    right: 0;
    cursor: pointer;

    &-icon {
      @include icon-image('filter', false, $transactions-url);
      @include width-height(35px, 35px);
      @include background-position-size(center, cover);
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;

      &-select {
        @include icon-image('filterSelect', false, $transactions-url);
      }
    }

    span {
      @include font-semi(14px);
      display: inline-block;
      vertical-align: middle;
    }
  }

  &-back {
    @include padding-one-var();
    @include position-float-top-left(-2px, 0);
    border: none;
    color: $white;
    background-color: transparent;
    text-decoration: none;
    z-index: 1;
    cursor: pointer;

    &-sticky {
      @include position-float-top-left(61%, 0);
      transform: translateY(-50%);
    }

    &-inner {
      @include flex(flex-start, center, row);
      gap: 10px;
      height: 26px;
    }

    &-icon {
      @include link($white, $white);
      margin-left: -5px;

      &-close {
        @include link($white, $white);
        @include width-height-same(30px !important);
        margin-top: 2px;
        cursor: pointer;
      }
    }

    &-close {
      @include position-float-top-right(-5px, -5px);
    }
    
    &:hover {
      color: $white;
    }

    &-text {
      @include font-medium(14px);
      text-transform: uppercase;

      @media (max-width: $max-mobile-width-sm) {
        display: none;
      }
    }
  }

  &-title {
    @include font-semi(18px);
    margin-bottom: 20px;

    &-no-bottom {
      @media (max-width: $max-mobile-width-sm) {
        margin-bottom: 0;
      }
    }

    &-hidden {
      pointer-events: none;
      opacity: 0;
    }

    &-center {
      text-align: center;
    }

    &-small {
      @include font-medium(16px);
      @include padding-two-var(2.5px);
    }

    &-medium {
      @include font-semi(18px);
      @include padding-two-var(2.5px);
    }

    &-large {
      @include font-semi(24px);

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(18px);
      }
    }

    &-indicator {
      @include font-semi(12px);
      @include padding-two-var(4px, 10px);
      background-color: $green-2;
      border-radius: 120px;
    }
  }

  &-link {
    @include link($light-purple, $purple-6);
    @include font-medium(14px);
    position: absolute;
    top: 2px;
    right: 0;
    cursor: pointer;
  }

  &-list {
    @include margin(0, -10px, -10px, -10px);
    overflow-x: auto;
    white-space: nowrap;

    &-item {
      @include responsive-width(150px, 125px);
      @extend %gallery;
      position: relative;

      &-detailed {
        width: 200%;
      }

      &-link {
        @include link($white, $white);
        @include margin-one-var(10px);
        @include padding-one-var(15px);
        @include flex(flex-start, flex-start, column);
        background-color: $dark-purple-7;
        border-radius: $border-radius-mobile;
        text-align: left;
        gap: 15px;

        &:hover {
          box-shadow: $glow-purple;

          .thumbnail {
            &-name {
              color: $purple-6;
            }

            &-details {
              &-wrapper {
                &-name {
                  color: $purple-6;
                }
              }
            }
          }
        }
      }
    }
  }
}

.thumbnail {
  @include background-position-size();
  @include margin-two-var(0, auto);
  border-radius: $border-radius-mobile;
  width: 100%;
  padding-top: 100%;
  position: relative;

  &-detailed {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: calc(100% - 20px);
    padding-top: calc(50% - 20px);
  }

  &-details {
    @include margin-two-var(0, 10px);
    @include padding-one-var(15px);
    text-align: left;
    background-color: $dark-blue-8;
    border-bottom-left-radius: $border-radius-mobile;
    border-bottom-right-radius: $border-radius-mobile;

    &-text {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 100px);
    }

    &-join {
      display: inline-block;
      vertical-align: middle;
      width: 100px;

      &-button {
        @include orange-button();
        @include padding-two-var(10px);
        width: 100%;
      }

      &-icon {
        @include margin(-2px, 5px);
        display: inline-block !important;
        width: 20px;
      }
    }

    &-wrapper {
      @include flex(flex-start, flex-start, column);
      width: 100%;
      gap: 15px;

      &-details {
        @include link($white, $white);
        @include flex(flex-start, flex-start, column);
        width: 100%;
        gap: 3px;

        &-marketplace {
          height: 106px;
        }
      }

      &-title {
        @include font(14px);
        @extend %text-ellipsis;
        color: $light-purple;
        width: 100%;
        text-align: left;
      }

      &-name {
        @include font-semi(14px);
        white-space: pre-wrap;
        text-align: left;
      }

      &-mgc {
        @include icon-image('mgc', false, $transactions-url);
        @include width-height-same(13px);
        @include background-position-size(center, contain);
      }

      &-price {
        @include font-semi(12px);
      }

      &-stats {
        @include flex(flex-start, center, row);
        gap: 5px;
        width: 100%;

        & > .game-pass-product-amount-medium {
          white-space: normal;
          overflow: hidden;
        }

        &-icon {
          @include icon-image('participants-icon', true, $tournaments-url);
          @include width-height(16px, 15px);
          @include background-position-size(center, 15px);
        }

        &-value {
          @include font-light(12px);
        }
      }

      &-button {
        @include orange-button();
        @include padding-two-var(10px);
        width: 100%;
        cursor: pointer;
      }
    }
  }

  &-name {
    @include font-semi(14px);
    @include link($white, $white);
    white-space: normal;
    text-align: left;
    width: 100%;

    &-detailed {
      @include padding-one-var();
    }

    &-centered {
      text-align: center;
    }

    &-height {
      @include flex(center, center, row);
      height: 42px;
    }
  }

  &-stats {
    @include padding-two-var(5px, 10px);
    @include font-semi(13px);
    @include position-float-top-right(0, 0);
    color: $white;
    background-color: $purple-translucent;
    border-top-right-radius: $border-radius-mobile;
    border-bottom-left-radius: $border-radius-mobile;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-one-var(5px);
      @include font-semi(11px);
    }

    span {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-participants, &-duration {
      &-icon {
        @include margin(-3px, 5px);
        width: 15px;
        display: inline-block !important;
      }
    }
  }
}