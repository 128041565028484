@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$icons-url: '../assets/icons/';
$marketplace-banners-url: '../assets/marketplace/banners/';

.launchpad {
  position: relative;
  
  &-banner {
    @include width-height-same(100%);
    margin-bottom: 20px;

    @media (max-width: $max-mobile-width-sm) {
      margin-bottom: 0;
    }
  }

  &-inner {
    width: 100%;
  }

  &-section {
    width: 100%;
    padding: 0;
    border-top: none;
  }

  &-tabs {
    width: 100%;

    @media (max-width: $max-mobile-width-xs) {
      width: calc(100% - 1px);
    }

    & > .rb-tabs-header {
      border-bottom: 2px solid $dark-blue-7 !important;
      position: sticky;
      top: -32px;
      right: 0;
      background-color: $dark-purple-1;
      z-index: 10;

      @media (min-width: $max-mobile-width-sm) {
        @include flex(center, center, row);
        gap: 20px;
      }

      @media (max-width: $max-mobile-width-sm) {
        @include margin-one-var();
        top: 60px;
      }

      & > .rb-tabs-items  {
        & > .rb-tabs-item.active {
          font-weight: 600 !important;
        }
      }
    }
    
    &-indicator {
      background-color: $pink-1 !important;
      height: 3px !important;
      bottom: -2.5px !important;
    }

    &-lists {
      justify-content: space-between;
      border-bottom: none !important;

      @media (min-width: $max-mobile-width-sm) {
        gap: 40px;
      }

      &-title {
        @include font(14px); 
        @include padding-two-var(10px !important, 16px !important);
        width: 100%;
        text-align: center;

        & > .active {
          @include font-semi(14px);
        }
      }

      &-grids {
        @include padding-two-var(30px);

        @media (max-width: $max-mobile-width-xs) {
          @include padding-one-var(20px);
        }
      }

      &-grid {
        padding-bottom: 30px;
        
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  &-wrapper {
    @include padding-two-var(20px);
    @include margin-one-var(0 !important);
    width: 100% !important;
    border-bottom: 1px solid $dark-blue-7;
    pointer-events: none;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(20px);
      pointer-events: all;
      cursor: pointer;
    }

    @media (max-width: $max-mobile-width-xs) {
      @include padding-one-var(20px);
    }
  }

  &-container {
    overflow-x: hidden;

    @media (max-width: $max-mobile-width-xs) {
      @include padding-two-var(20px);
      overflow: visible;
    }
  }

  &-image {
    width: 100%;
    border-radius: 12px;

    @media (max-width: $max-mobile-width-sm) {
      border: none;
      object-fit: cover;
    }

    &-wrapper {
      @include padding-one-var(0 !important);
    }
  }

  &-details {
    @include flex(space-between, flex-start, column !important);
    gap: 15px;

    &-next {
      @include position-float-top-right(4px, -2px);
      height: 16px !important;
      display: none !important;
  
      @media (max-width: $max-mobile-width-sm) {
        display: block !important;
      }
    }
    
    &-id {
      @include flex(flex-start, flex-start, column !important);
      position: relative;
      gap: 15px;
      padding-top: 0 !important;

      @media (max-width: $max-mobile-width-sm) {
        @include flex(flex-start, flex-start, column);
        gap: 6px;
      }
    }

    &-name {
      @include font-semi(20px);
      padding-right: 15px;

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(16px);
      }
    }

    &-desc {
      @include font();
      color: $grey-6;

      @media (max-width: $max-mobile-width-sm) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-stats {
      @include flex(center, center, column !important);
      @include padding-two-var(15px);
      background-color: $dark-purple-3;
      border: $purple-border;
      border-radius: 8px;
      
      @media (max-width: $max-mobile-width-sm) {
        padding: 0;
        background-color: transparent;
        border: none;
        border-radius: 0px;
        border-right: 1px solid $dark-blue-7;

        &:first-child {
          padding-right: 10px;
        }

        &:last-child {
          border: none;
        }
      }

      &-box {
        @include padding(0px, 15px, 0px, 0px);
        gap: 10px;

        @media (max-width: $max-mobile-width-sm) {
          padding: 0;
          background-color: transparent;
          border: none;
          border-radius: 0px;
          border-right: 1px solid $dark-blue-7;

          &:first-child {
            padding-right: 5px;
          }

          &:nth-child(2){
            @include padding-two-var(0px, 5px);
          }
    
          &:last-child {
            border: none;
          }
        }
      }

      &-wrapper {
        display: flex;
        gap: 20px;
        width: 100%;

        @media (max-width: $max-mobile-width-sm) {
          gap: 0;
        }
      }
      
      &-value {
        @include font-semi(20px);
        color: $pink-1;

        @media (max-width: $max-mobile-width-sm) {
          @include font-semi(14px);
          text-align: center;
        }
      }

      &-label {
        @include font(12px);
        color: $grey-6;

        @media (max-width: $max-mobile-width-sm) {
          @include font(9px);
        }
      }

      &-button {
        @include purple-button();
        @include padding-two-var(10px);
        width: 100%;
        max-width: 300px;
        pointer-events: all;

        @media (max-width: $max-mobile-width-sm) {
          display: none;
        }
      }
    }
  }
}

.marketplace {
  overflow-x: hidden;

  &-container {
    @include margin-one-var(0 !important);
    padding-top: 30px;
    width: 100% !important;
    gap: 20px;

    &-box {
      @include font(14px);
      @include padding-one-var(20px);
      @include flex(center, center, row);
      border-radius: 8px;
      height: 122px;
      margin-top: 0;
      background-color: $dark-blue-7;

      @media (min-width: $min-mobile-width-sm) {
        margin-top: 40px;
      }
    }
  }

  &-header {
    @include font-semi(18px);
  }

  &-banner {
    @include icon-image('desktop', false, $marketplace-banners-url);
    @include width-height(100%, 230px);
    @include background-position-size(center, contain);

    @media (max-width: $max-mobile-width-sm) {
      @include icon-image('mobile', false, $marketplace-banners-url);
      @include width-height(100%, 157px);
      @include background-position-size(center, contain);
    }
  }

  &-title {
    @include font-semi(14px);
    @include padding-two-var(10px);
    text-align: center;
  }

  &-image {
    @include margin-one-var();
    @include width-height(100%, 0);
    @include background-position-size(center, contain);
    background-repeat: no-repeat;
    border-radius: $border-radius-mobile;
    padding-top: 100%;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  &-box {
    @include padding-one-var(0 !important);
    width: calc(100% / 2 - 10px) !important;
    max-width: none !important;
    flex-basis: auto !important;
    -webkit-flex-basis: auto !important;
    -moz-flex-basis: auto !important;

    @media (min-width: $min-width-grid-item-sm) {
      width: calc(100% / 3 - 13.5px) !important;
    }

    @media (min-width: $min-width-grid-item-md) {
      width: calc(100% / 4 - 15px) !important;
    }

    @media (min-width: $min-desktop-width-xl) {
      width: calc(100% / 6 - 17px) !important;
    }

    &-name {
      @include font(14px);
      color: $light-purple;

      @media (max-width: $max-mobile-width-sm) {
        @include font(12px);
      }
    }

    &-title {
      @include font-medium(14px);

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(14px);
      }
    }

    &-icon {
      @include icon-image('mgo-coin', true, $icons-url);
      @include background-position-size(center, contain);
      @include width-height-same(16px);

      @media (max-width: $max-mobile-width-sm) {
        @include width-height-same(12px);
      }
    }

    &-mgc {
      @include font-semi(14px);
      margin-left: 3px;

      @media (max-width: $max-mobile-width-sm) {
        @include font-semi(10px);
        margin-left: 0;
      }
    }
  }
}