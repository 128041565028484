@import '../../baseStyles/colors.scss';
@import '../../baseStyles/extends.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$gamepass-url: '../../assets/gamepass/';
$gamepass-cards-url: '../../assets/gamepass/cards/';
$wen-lambo-bg-url: '../../assets/gamepass/bg/page/';

.game-pass-screen {
  @include padding-one-var();
  @include width-height-same(100%);

  &-inner {
    background-color: $dark-pink-3;
    background-image: $wen-lambo-gradient;
    max-width: none;
  }

  &-max-width {
    @include margin-two-var(0, auto);
    width: 100%;
    max-width: $max-layout-width;
  }

  &-banner {
    @include flex(center, center, row);
    @include background-position-size();
    width: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    padding-bottom: 50px;

    @media (max-width: $max-mobile-width-sm) {
      padding-bottom: 0;
    }

    &-guest {
      padding-bottom: 0;
    }

    &-content {
      @include flex(center, center, column);
      padding-bottom: 20px;

      @media (max-width: $max-mobile-width-xs) {
        width: calc(100% - 40px);
      }
    }

    &-logo {
      width: 100%;
      max-width: 1200px;
      cursor: pointer;

      @media (max-width: $max-tablet-width) {
        max-width: 900px;
      }

      @media (max-width: $max-mobile-width-sm) {
        width: calc(100% + 40px);
        max-width: 400px;
      }
    }
    
    &-button {
      @include orange-button(12px);
      @include padding-one-var(9px);
      width: 100%;
      max-width: 380px;
      margin-top: 15px;
      letter-spacing: 1px;
      cursor: pointer;

      @media (max-width: $max-mobile-width-sm) {
        margin-top: -80px;
      }
    }

    &-view-mechanics {
      @include link($white, $white);
      @include font-semi(14px);
      border-radius: $button-border-radius;
      margin-left: 10px;
      margin-top: 15px;
      cursor: pointer;
    }
  }

  &-sections {
    width: 100%;
    margin-top: -80px;
    padding-bottom: 30px;

    @media (max-width: $max-mobile-width-sm) {
      margin-top: 0;
    }

    &-guest {
      margin-top: 0;
    }
  }

  &-timer-container {
    @include margin(30px, 20px, 0, 20px);
    background-color: $dark-purple-3-translucent;
  }

  &-gold-coins {
    @include padding(20px, 20px, 0, 20px);
  }

  &-section {
    @include margin-two-var(0, auto);
    @include padding(30px, 20px, 0, 20px);
    width: 100%;
    max-width: 1346px;

    @media (max-width: $max-mobile-width-sm) {
      @include padding(30px, 20px, 0, 20px);
    }

    &:last-child {
      @media (max-width: $max-mobile-width-sm) {
        padding-bottom: 20px;
      }
    }

    &-carousel {
      border-radius: $border-radius-mobile;
      
      @media (max-width: $max-mobile-width-sm) {
        @include margin(0, 0, -25px);
      }

      &-banner {
        width: 100%;
        border-radius: $border-radius-mobile;
        cursor: pointer;
      }
    }

    &-tutorial {
      position: relative;
      z-index: 20;
    }

    &-highlighted {
      @include padding(2px, 0px, 20px);
      border-radius: $border-radius-mobile;

      @media (max-width: $max-mobile-width-sm) {
        @include padding-one-var();
        max-width: none;
      }
    }

    &-even {
      background-color: transparent;
    }

    &-inner {
      @include padding-one-var();
      border-top: none;

      &-home {
        padding-bottom: 30px;
      }

      &-themed {
        .section {
          &-link {
            @include link($white, $white);
          }
  
          &-list {
            &-item-link {
              @include padding-two-var(15px, 10px);
              background-color: $dark-purple-10;
              
              .thumbnail-details-wrapper-title {
                color: $white;
              }
  
              &::before {
                @include wen-lambo-thumbnail-border(9px);
              }
    
              &:hover {
                box-shadow: $glow-yellow;

                .thumbnail {
                  box-shadow: none;

                  &-details-wrapper-name {
                    color: $white;
                  }
                }
              }
            }
    
            .slick {
              &-prev, &-next {
                background: transparent;
                height: 50%;
              }
            }
          }
        }
      }
    }

    &-ads {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      flex-wrap: wrap;

      & > :nth-child(3) {
        grid-column: 1 / -1;
      }

      @media (max-width: $max-mobile-width-sm) {
        @include flex(flex-start, flex-start, column);
        gap: 30px;
      }

      &-gap {
        padding-bottom: 60px !important;
      }
    }

    &-ad {
      width: 100%;

      @media (max-width: $max-mobile-width-sm) {
        width: 100%;
      }

      &-banner {
        width: 100%;
        border-radius: $border-radius-mobile;
        cursor: pointer;
      }
    }
  }

  &-buy {
    align-items: center;

    &-new {
      &::before {
        background: $free-spin-gradient !important;
      }
    
      &:hover {
        box-shadow: $glow-teal !important;
      }
    }

    &-wrapper {
      align-items: center;
      cursor: pointer;

      &-recommended {
        gap: 10px;
      }
    }

    &-card {
      @include position-float-top-left(0, 0);
      @include width-height-same(100%);
      object-fit: cover;
      object-position: center;

      &-wrapper {
        width: 100%;
        position: relative;

        &::before {
          content: '';
          display: block;
          padding-top: 100%; 
        }
      }

      &-recommended {
        @include margin-two-var(15px);
        @include position-float-top-left(0, 0);
        @include width-height-same(100%);
        object-fit: cover;
        object-position: center;
        border-radius: $border-radius-mobile;
      }
    }

    &-recommended-badge {
      @include game-pass-recommended-badge(10px, 10px);
    }

    &-new-badge {
      @include game-pass-recommended-badge(10px, 10px, $free-spin-gradient);
    }

    &-buttons {
      @include flex(flex-start, flex-start, row);
      gap: 10px;
      width: 100%;
    }

    &-button {
      @include orange-button();
      @include padding-two-var(10px);
      width: 100%;
      cursor: pointer;

      &-new {
        @include purple-blue-button();
        @include padding-two-var(10px);
      }
    }
  }

  &-spin-the-wheel {
    &-banner {
      width: 100%;
    }
  }

  &-thumbnail {
    &-details {
      @include margin(24px);
      height: 66px;
    }
  }
}

.invitation {
  &-banner {
    @include width-height-same(100%);
    border-radius: 5px;

    &-container {
      @include flex(center, center, row);
      @include padding-two-var(0px, 20px);
      @include width-height(100%, auto);
      margin-top: 20px;

      @media (max-width: $max-mobile-width-sm) {
        margin-bottom: -10px;
      }
    } 
  }
}