@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/fonts.scss';
@import '../../../baseStyles/mixins.scss';
@import '../../../baseStyles/settings.scss';

.tournament-banner {
  @include margin(30px, 30px, 0, 30px);

  @media (max-width: $max-mobile-width-sm) {
    @include margin(-20px, -20px, 0);
    width: 100% !important;
  }

  &-cover {
    @include width-height(100%, 420px);
    @include background-position-size();
    border-radius: $border-radius-mobile;
    position: relative;
    z-index: 1;
    pointer-events: none;

    @media (max-width: $max-mobile-width-sm) {
      @include width-height(100%, 200px);
      @include margin-one-var();
      border-radius: 0;
    }

    &-link {
      display: block;
    }
  }

  &-design {
    @include width-height(calc(100% - 60px), 440px);
    border-radius: $border-radius-desktop;
    border: $purple-border;
    display: block;
    position: absolute;
    top: -10px;
    z-index: 0;

    @media (max-width: $max-mobile-width-sm) {
      display: none;
      border-radius: 0;
    }
  }

  &-details {
    @include padding-one-var();
    position: relative;
    z-index: 1;

    @media (max-width: $max-mobile-width-sm) {
      @include padding-two-var(0, 20px);
      width: auto;
      margin-left: 0;
    }
  }

  &-description {
    @include padding-one-var();
    width: 100%;
    vertical-align: middle;
  }

  &-info {
    @include padding(20px);
  }

  &-texts {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    padding-right: 30px;

    @media (min-width: $min-desktop-width-sm) and (max-width: $max-desktop-width-lg) {
      width: 50%;
    }
  }

  &-text {
    width: 100%;
    vertical-align: top;

    @media (max-width: $max-mobile-width-sm) {
      text-align: center;
      padding-right: 0;
    }
  }

  &-title {
    @include font-semi(24px);
    margin-bottom: 20px;
  }

  &-short-desc {
    @include font-light(14px);

    @media (max-width: $max-tablet-width) {
      @include font-light();
    }
  }

  &-button {
    @include purple-button();
    width: calc(50% - 10px);
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $max-tablet-width) {
      width: 100%;
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto !important;
      display: block !important;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: $max-mobile-width-sm) {
      max-width: 300px;
    }

    &-wrapper {
      width: 100%;
      max-width: 700px;
      vertical-align: top;
      text-align: left;
      margin-top: 20px;

      @media (max-width: $max-mobile-width-sm) {
        max-width: none;
        text-align: center;
      }
    }

    &-buy {
      @include orange-button();

      &-icon {
        @include margin(-2px, 5px);
        display: inline-block !important;
        width: 20px;
      }
    }
  }

  &-prizes-stats {
    width: 100%;

    &-wrapper {
      width: 40%;
      display: inline-block;
      vertical-align: top;

      @media (min-width: $min-desktop-width-sm) and (max-width: $max-desktop-width-lg) {
        width: 50%;
      }
    }

    &-inner {
      border: $purple-border;
      border-radius: $border-radius-mobile;
      background-color: $dark-purple-1;
    }

    &-item {
      border-top: $purple-border;

      &:first-child {
        border-top: none;
      }
    }
  }

  &-stats {
    @include padding-two-var(15px, 20px);
    @include font-semi(14px);
    color: $white;
    text-align: center;

    span {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-participants, &-duration {
      &-icon {
        @include margin(-3px, 5px);
        width: 15px;
      }
    }
  }

  &-view-leaderboard {
    @include font-medium(14px);
    @include margin(-16px, auto, 0, auto);
    @include padding(5px, 15px, 5px, 25px);
    display: block;
    width: 80%;
    max-width: 200px;
    color: $white;
    background-color: $purple-1;
    border: none;
    border-radius: $button-border-radius;
  }
}