@import '../../../baseStyles/colors.scss';
@import '../../../baseStyles/mixins.scss';

.general-dropdown {
  &-container {
    @include flex(center, center, row);
    outline: none;

    &-disable {
      pointer-events: none;
    }
  }

  &-caret {
    @include font-semi(26px);
  }

  &-toggle {
    @include padding();
  }

  &-menu {
    @include font-semi(14px);
    background-color: $dark-purple-7;
    color: $white !important;

    &-item {
      @include flex(flex-start, center, row);
      width: 100%;
      color: $white;
      gap: 6px;

      &:hover {
        background-color: $cream;
      }
    }
  }

  &-title {
    &-container {
      @include flex(center, center, row);
      @include margin-one-var();
      @include padding-one-var();
      width: 100%;
      gap: 6px;
    }

    &-icon {
      @include width-height-same(30px);

      &-container {
        @include width-height-same(30px);
        @include flex(flex-start, center, row);
      }
    }
  }

  &-toggle {
    @include flex(center, center, row);
    @include font-semi(18px);
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border: none !important;
    padding-right: 0 !important;

    &-container {
      @include flex(center, center, row);
    }
  }
}