@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$icons-url: '../../assets/icons/';

.withdrawal {
  @include width-height(100%, 96%);
  @include flex(space-between, center, column);
  text-align: center;
  position: relative;
  text-align: start;

  &-content {
    height: calc((var(--vh, 1vh) * 100) - 126px);

    @media (max-width: $max-mobile-width-sm) {
      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }

    &-intent {
      height: 100%;
    }
  }

  &-container {
    @include padding(20px, 30px, 20px, 30px);
    @include margin(0px, -30px, 30px, -30px);
    background-color: $dark-purple-7;
    position: relative;

    @media (max-width: $max-mobile-width-sm) {
      @include padding(20px, 20px, 20px, 20px);
      @include margin(0px, -20px, 20px, -20px);
    }
  }

  &-section {
    @include padding(0, 0, 20px);
    height: 100%;
    border-top: none;
  }

  &-rate {
    &-title {
      @include font-semi(14px);
    }

    &-box {
      @include padding-two-var(12px, 10px);
      @include flex(space-between, center, row);
      @include font(14px);
      background: $dark-purple-4-translucent;
      border-radius: 5px;
      margin-top: 20px;
    }
  }

  &-row-container {
    @include flex(space-between, center, row);
    gap: 6px;
  }

  &-title {
    @include font-semi(14px);

    &-amount {
      @include font-semi(24px);
      margin-top: 10px;
    }

    &-currency {
      @include font(14px);
      margin-left: 5px;
    }
  }

  &-currency {
    @include font-semi(16px);

    &-icon {
      @include icon-image('mgo-coin', true, $icons-url);
      @include background-position-size(center, cover);
      @include width-height-same(30px);
    }
  }

  &-country {
    @include width-height-same(30px);
    margin-right: 5px;
  }

  &-input {
    @include font-semi(24px);
    @include padding-two-var(5px, 15px);
    @include margin-two-var(10px);
    color: $white;
    background-color: $light-purple-translucent;
    border-radius: 20px;
    border: none;
    width: 100%;
    outline: none;
  }

  &-text {
    @include font(14px);

    &-balance {
      @include font-semi(14px);
      margin-left: 5px;
    }
  }

  &-convert {
    &-all {
      @include font-semi(14px);
      color: $pink-6;
      cursor: pointer;
    }

    &-icon {
      @include icon-image('convert', true, $icons-url);
      @include background-position-size(center, cover);
      @include width-height-same(100%);

      &-container {
        @include flex(center, center, row);
        @include position-float-bottom-left(-28px, 50%);
        @include padding-one-var(13px);
        @include width-height-same(50px);
        background-color: $purple-7;
        border-radius: 50px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transform: translateX(-50%);
        cursor: pointer;
      }
    }
  }

  &-button {
    @include purple-button();
    @include flex(center, center, column);
    background-color: transparent;
    width: 100%;
    cursor: pointer;

    &-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &-wrapper {
      @include padding(20px, 0, 10px);
      background-color: $dark-purple-1;
      max-width: $max-layout-width;
      width: 100%;
    }
  }

  &-conversion {
    &-container {
      @include padding-one-var(15px);
      @include flex(space-between, center, row);
      @include font(14px);
      background-color: $purple-translucent-2;
      border-radius: 5px;
      margin-top: 10px;
    }

    &-title {
      @include font-semi(14px);
    }
  }

  &-form {
    @include flex(space-between, center, column);
    height: 96%;

    &-title {
      @include font-semi(16px);
    }

    &-button {
      @include padding-two-var(6px);

      &-fee {
        @include font();
      }
    }

    &-notes {
      @include padding-two-var(20px, 18px);
      @include flex(flex-start, flex-start, column);
      background-color: $confirm-widthrawal-bg;
      border: $purple-light-border-2;
      border-radius: 8px;
      margin-top: 20px;

      &-text {
        & > a {
          color: $pink-1;
          text-decoration: none;
        }
      }
    }

    &-input {
      @include font(14px);
      @include padding-two-var(12px, 16px);
      @include margin(10px);
      background-color: $dark-purple-7;
      width: 100%;
      color: $white;
      border: none;
      outline: none;
      border-radius: 40px;

      &-wrapper {
        margin-top: 20px;
      }

      &-title {
        @include font(14px);
        color: $light-purple;
      }
    }
  }
}