@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

.trending-game {
  @include grid();

  &-item {
    &-name {
      @include padding-two-var(10px, 0);
      text-align: left;
      cursor: pointer;

      &:hover {
        color: $purple-6;
      }
    }

    &-image-container {
      cursor: pointer;
    }

    &-link {
      cursor: default;
    }
  }

  &-loading {
    @include font-medium(14px);
    text-align: center;
    animation: blink 2s linear infinite;
  }

  &-mobile {
    @include margin-two-var(0, -30px);

    @media (max-width: $max-mobile-width-sm) {
      @include margin-two-var(0, -20px);
    }

    &-item {
      position: relative;

      &-wrapper {
        @include padding-two-var(20px, 30px);
        display: flex;
        gap: 20px;
        border-top: 1px solid $dark-blue-7;
  
        @media (max-width: $max-mobile-width-sm) {
          @include padding-two-var(15px, 20px);
        }
      }
    }

    &-image {
      @include width-height(250px, 250px);
      border-radius: $border-radius-mobile;
      cursor: pointer;
  
      @media (max-width: $max-mobile-width-sm) {
        @include width-height-same(130px);
        border: none;
        object-fit: cover;
      }
  
      &-wrapper {
        @include width-height-same(fit-content);
        border: 1px solid $purple-1;
        padding: 10px;
  
        @media (max-width: $max-mobile-width-sm) {
          border: none;
          padding: 0px;
        }
      }
    }

    &-details {
      @include flex(flex-start, flex-start, column);
      width: 100%;
      gap: 10px;
  
      &-name {
        @include font-medium(20px);
        @include link($white, $purple-6);
  
        @media (max-width: $max-mobile-width-sm) {
          @include font-medium(16px);
        }
      }
    }
  }
}

.scroll-container {
  height: calc(100vh - 126px);
  height: calc(var(--vh, 1vh) * 100 - 126px);
}

@keyframes blink {
  0%    { opacity: 0.25; }
  50%   { opacity: 0.75; }
  100%  { opacity: 0.25; }
}