@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';

$logo-url: '../../assets/logo/';

.otp-auth {
  @include flex(center, center, column);
  width: 100%;

  &-header-logo {
    @include icon-image('logo', true, $logo-url);
    @include width-height(175px, 34px);
    @include margin-two-var(0, auto);
    @include background-position-size(center, 175px);
    display: block;
  }

  &-dialog {
    @media (max-width: $max-mobile-width-sm) {
      align-items: center;
    }
  }

  &-backdrop {
    &.show {
      opacity: 0;
    }
  }

  &-error {
    @include font-semi();
    @include margin(5px);
    color: $red-2;
    width: 100%;
    text-align: left;
  }

  &-input {
    @include font-semi(20px);
    @include flex(center, center, row);
    @include padding-two-var(8px, 20px);
    @include width-height(100%, 46px);
    @include margin(10px);
    gap: 20px;
    border-radius: 30px;
    letter-spacing: 20px;
    background-color: $white;

    &-text {
      width: 100%;
    }

    &-number {
      max-width: 20px;
      text-align: center;
    }

    &-error {
      border: $red-border
    }
  }

  &-text {
    @include font-semi(14px);
    color: $pink-6;
    cursor: pointer;

    &-wrapper {
      @include padding(20px);
      @include flex(center, center, column);
      gap: 15px;
    }
  }

  &-email-wrapper {
    @include padding(15px);
    @include flex(center, center, column);
    gap: 15px;
  }

  &-input-grid {
    display: flex;
    flex-direction: row;

    div {
      justify-content: center;
    }
  }

  &-digit {
    @include font-semi(20px);
    @include width-height-same(40px !important);
    border: none;
    color: $dark-blue-7;
    outline: none;
    text-align: center;

    &::placeholder {
      color: $light-purple;
    }

    &-error {
      color: $red-2;
    }
  }
}