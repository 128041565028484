@import '../../baseStyles/colors.scss';
@import '../../baseStyles/fonts.scss';
@import '../../baseStyles/mixins.scss';
@import '../../baseStyles/settings.scss';
@import '../../baseStyles//extends.scss';

$icons-url: '../../assets/icons/';
$launchpad-url: '../../assets/launchpad/';
$astro-pink-url: '../../assets/launchpad/events/astro-pink/';
$music-fest-url: '../../assets/launchpad/events/music-fest/';
$stw-url: '../../assets/launchpad/events/stw/';

.launchpad {
  &-social {
    &-wrapper {
      @include flex(center, center, row);
      @include width-height-same(40px);
      background: $dark-purple-9;
      border-radius: 50%;
      padding: 5px;
      cursor: pointer;
    }
  }

  &-item {
    display: grid;
    grid-template-columns: 400px 500px;
    gap: 20px;
    padding-top: 50px;

    @media (max-width: $max-tablet-width) {
      grid-template-columns: 1fr;
      @include padding(40px, 20px, 20px, 20px);
    }

    &-back {
      position: absolute;
      top: 0px;
      left: 0px;
      cursor: pointer;
      color: $white;

      &:hover {
        color: $white;
      }

      @media (max-width: $max-tablet-width) {
        top: 5px;
        left: 15px;
      }
    }

    &-container {
      @include flex(center, flex-start, row);
      padding-bottom: 50px;
      overflow-x: hidden;

      @media (max-width: $max-tablet-width) {
        @include width-height(100%, 100%);
        @include padding(0px, 0px, 20px, 0px);
      }
    }

    &-image {
      @include width-height(100%, 100%);
      object-fit: cover;
      border-radius: 12px;
      max-width: 400px;

      @media (max-width: $max-tablet-width) {
        @include width-height(100%, 100%);
      }

      &-container {
        @media (max-width: $max-tablet-width) {
          @include flex(center, center, row);
        }
      }

      &-wrapper {
        border-radius: 12px;
        width: fit-content;
        height: fit-content;

        @media (max-width: $max-tablet-width) {
          border: none;
          padding: 0px;
        }
      }
    }
  }


  &-details {
    &-header {
      @include flex(space-between, center, row);
      gap: 10px;
      width: 100%;
      cursor: pointer;

      &-content {
        @include flex(center, center, row);
        gap: 10px;
      }
    }

    &-text {
      @include font-bold(15px);
      padding-top: 3px;
    }

    &-description {
      @include font(13px);
      color: $grey-6;
      text-align: left;
      white-space: pre-line;
    }

    &-info {
      @include icon-image('info', false, $launchpad-url);
      @include width-height-same(30px);
      background-size: cover;
    }

    &-utility {
      @include icon-image('utility', false, $launchpad-url);
      @include width-height-same(30px);
      background-size: cover;
    }

    &-traits {
      @include icon-image('traits', false, $launchpad-url);
      @include width-height-same(30px);
      background-size: cover;
    }

    &-spin-the-wheel-banner {
      @include launchpad-item-banner($stw-url, 220px, 162px);
    }

    &-music-fest-banner {
      @include launchpad-item-banner($music-fest-url, 186px, 137px);
    }

    &-sm-event-banner {
      @include launchpad-item-banner($astro-pink-url, 220px, 168px);
    }

    &-link {
      @include flex(flex-start, center, row);
      @include font-semi(13px);
      color: $pink-1;
      gap: 3px;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: $pink-1;
      }

      &-arrow {
        height: 15px !important;
      }
    }
  }

  &-back-mobile {
    @include padding-one-var();
    width: calc(100% - 40px);
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    border-top: none;
  }

  &-sections {
    @include flex(space-between, space-between, column);

    &-name {
      @include font-medium(30px);

      @media (max-width: $max-mobile-width-sm) {
        text-align: center;
      }
    }

    &-share {
      @include font-semi(13px);
      text-transform: uppercase;

      &-wrapper {
        @include padding(8px, 20px, 8px, 13px);
        @include flex(center, center, row);
        cursor: pointer;
        gap: 2px;
        border: 1px solid $white;
        border-radius: 20px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        &:hover {
          box-shadow: $glow;
        }

        @media (max-width: $max-mobile-width-xs) {
          position: relative;
          top: 0;
          transform: none;
        }
      }

      &-icon {
        height: 18px !important;
      }
    }

    &-share {
      @include font-semi(13px);
      text-transform: uppercase;

      &-wrapper {
        @include padding(8px, 20px, 8px, 13px);
        @include flex(center, center, row);
        cursor: pointer;
        gap: 2px;
        border: 1px solid $white;
        border-radius: 20px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        &:hover {
          box-shadow: $glow;
        }

        @media (max-width: $max-mobile-width-xs) {
          position: relative;
          top: 0;
          transform: none;
        }
      }

      &-icon {
        height: 18px !important;
      }
    }

    &-desc {
      @include font(13px);
      @include padding-two-var(20px);
      color: $grey-6;
      white-space: pre-line;

      @media (max-width: $max-mobile-width-sm) {
        text-align: left;
      }
    }

    &-stats {
      @include flex(center, center, column);
      @include padding-two-var(8px);
      background-color: $dark-purple-3;
      border: $purple-border;
      border-radius: 8px;
      width: 100%;

      &-wrapper {
        display: flex;
        gap: 20px;
        width: 100%;

        @media (max-width: $max-mobile-width-sm) {
          gap: 10px;
        }
      }

      &-value {
        @include font-bold(20px);
        color: $pink-1;
      }

      &-label {
        @include font-medium(12px);
      }

      &-desc {
        @include font-medium(12px);
        color: $grey-6;
      }
    }
  }
  
  &-item-progress {
    @include margin-two-var(15px);
    position: relative;
  
    &-bar {
      @include progress-bar();
      box-shadow: $glow;
  
      .progress-bar {
        background-color: $purple-1;
      }
    }
  
    &-tick {
      @include width-height-same(60px);
      @include icon-image('progress-bar-arrow', true, $icons-url);
      @include background-position-size(center, 60px);
      position: absolute;
      top: -27.5px;
    }
  }
}

.mint {
  @include flex(space-between, space-between, column);
  @include padding-two-var(20px);
  border-bottom: 1px solid $dark-blue-7;

  &:last-child {
    border-bottom: none;
  }

  &-title {
    @include flex(flex-start, center, row);
    gap: 5px;
    flex-wrap: wrap;
  }
  
  &-subtitle {
    @include font(12px);
  }

  &-wrapper {
    @include flex(center, flex-start, column);
    gap: 10px;

    &-hide {
      gap: 0;
    }
  }

  &-progress {
    @include font-bold(14px);
    text-align: left;
  }

  @media (max-width: $max-mobile-width-sm) {
    text-align: center;
  }

  &-button {
    @include purple-button();
    @include padding-two-var(10px);
    width: 100%;
  }
}

.nft-voucher {
  @media (max-width: $max-mobile-width-sm) {
    text-align: center;
  }

  &-about {
    cursor: pointer;

    &-wrapper {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 3px;
      width: 100%
    }

    &-text {
      @include font-semi(13px);
      color: $pink-1;
      text-transform: uppercase;
    }

    &-icon {
      color: $pink-1;
      height: 15px !important
    }
  }

  &-about {
    cursor: pointer;

    &-wrapper {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 3px;
      width: 100%
    }

    &-text {
      @include font-semi(13px);
      color: $pink-1;
      text-transform: uppercase;
    }

    &-icon {
      color: $pink-1;
      height: 15px !important
    }
  }

  &-code {
    @include font(12px);
    text-align: center;
  }

  &-redeemed {
    @include flex(center, center, column);
    @include padding-two-var(20px, 20px);
    background-color: $dark-purple-9;
    border-radius: 12px;
    width: 100%;
    gap: 20px;
    

    &-title {
      @include font-medium(15px);
    }

    &-desc {
      @include font-medium(12px);
      color: $grey-6;
    }
  }

  &-minting {
    @include position-float-bottom-left(0, 0);
    width: 100vw;
    background: $black;
    z-index: 6;
    overflow-x: hidden;

    @media (max-width: $max-mobile-width-sm) {
      overflow-x: visible;
    }

    &-bitto {
      bottom: 0;
    }

    &-button {
      @include purple-button();
      @include padding-two-var(14px !important);
      width: 100%;
      max-width: 350px !important;
      cursor: pointer;
    }

    &-header {
      margin-bottom: 20px;
      max-width: 153px;
    }

    &-contents {
      @include flex(flex-start, center, column);
      @include padding(30px);
      
      &-share {
        @include font-semi();
        color: $white;
        background: transparent;
        border: none;
        outline: none;

        &-wrapper {
          @include flex(center, center, row);
          @include padding-two-var(30px);
        }

        &-icon {
          @include icon-image('share', false, $launchpad-url);
          @include background-position-size(center, contain);
          @include width-height-same(15px);
        }
      }

      &-title {
        @include font-light(14px);
        @include padding-two-var(0, 30px);
        text-align: center;
      }

      &-logo {
        max-width: 195px;
      }

      &-button {
        @include white-button();
        color: $blue-3;
        width: 100%;

        &:hover {
          color: $blue-3;
        }
      }
    }

    &-load {
      @include width-height(100vw, 100vh);
      @include position-top-left(fixed, 0, 0);
      background: $black;
      z-index: 10;
    }

    &-back {
      @include position-float-top-left(20px, 15px);
      cursor: pointer;
    }

    &-button {
      @include purple-button(12px);
      width: 100%;
      max-width: 400px;
      margin-top: 20px;
    }

    &-box {
      @include flex(center, center, column);
      @include width-height-same(fit-content);
      padding: 10px;
      border-radius: 12px;
      text-align: center;

      @media (max-width: $max-mobile-width-sm) {
        padding: 0px;
        border: none;
      }
    
    }

    &-image {
      @include width-height-same(328px);
      border-radius: 12px;
      object-fit: cover;

      @media (max-width: $max-mobile-width-sm) {
        @include width-height(100%, 280px);
        max-width: 280px;
      }

      @media (max-height: 749px) {
        @include width-height-same(210px);
      }

      &-large {
        @include width-height-same(304px);
        max-width: 100%;
      }
    }
    
    &.nft-claim {
      @include flex(center, center, column);
      @include padding(0px, 20px, 20px, 20px);
      background-color: $dark-purple-1;
      height: calc((var(--vh, 1vh) * 100) - 64px);
      
      @media (max-width: $max-mobile-width-sm) {
        padding: 0px;
      }
    }

    &-container {
      @include flex(center, center, column);
      @include width-height(100%, 100%);
      @include padding(20px, 20px, 20px, 20px);
      gap: 50px;
      max-width: 1360px;
      position: relative;
      background-color: $dark-purple-1;

      &-transparent {
        background-color: transparent;
      }

      &-content {
        @include width-height(100%, 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;

        @media (max-width: $max-mobile-width-sm) {
          padding-top: 40px;
          gap: 10px;
        }

        &-gap {
          padding: 0;
          gap: 0px
        }
      }
    }

    &-body {
      @include flex(center, center, column);
      width: 100%;
      max-width: 350px;

      &-bg {
        @include padding-one-var(20px);
        background: $light-gray-translucent-1;
        gap: 5px;
        border-radius: 10px;
        max-width: 345px;
      }

      &-center {
        height: 100%
      }
    }

    &-wrapper {
      @include flex(center, center, column);
      @include position-float-top-left(50%, 50%);
      transform: translate(-50%, -50%);
      width: 100%;
      gap: 10px;
    }

    &-wheel {
      @include font-semi(12px);
      @include position-float-top-left(-10px, 50%);
      background-color: $dark-purple-1;
      text-align: center;
      transform: translate(-50%);

      @media (max-width: $max-mobile-width-sm) {
        @include position-top-left(relative, 0, 0);
        transform: none;
        padding-bottom: 15px;
      }
    }

    &-banner {
      @include icon-image('banner', false, $stw-url);
      @include background-position-size(center, contain);
      width: 100%;
      height: 155px;
      max-width: 350px;

      @media (min-width: $min-tablet-width) {
        @include icon-image('banner-large', false, $stw-url);
        max-width: 100%;
        height: 19vh;
      }
    }

    &-event {
      @include icon-image('banner', false, $astro-pink-url);
      @include background-position-size(center, contain);
      @include width-height(100%, 155px);
      max-width: 350px;

      @media (min-width: $min-tablet-width){
        @include icon-image('banner-large', false, $astro-pink-url);
        max-width: 100%;
        height: 19vh;
      }
    }

    // &-fest {
    //   @include icon-image('banner', false, $music-fest-url);
    //   @include background-position-size(center, contain);
    //   width: 100%;
    //   height: 155px;
    //   max-width: 350px;

    //   @media (min-width: $min-tablet-width){
    //     @include icon-image('banner-large', false, $music-fest-url);
    //     max-width: 100%;
    //     height: 19vh;
    //   }
    // }

    &-footer {
      @include flex(center, center, column);
      @include padding-two-var(20px);
      background-color: $dark-purple-1;
      border-top: 1px solid $dark-blue-7;
      width: 100%;
      position: relative;
      text-align: center;

      @media (max-width: $max-mobile-width-sm) {
        border-top: 4px solid $dark-blue-7;
      }
    }

    &-icon {
      @include width-height-same(230px);
      margin-bottom: 23px;
    }

    &-text {
      @include font-bold(20px);
      text-align: center;
    }

    &-desc {
      @include font-semi(12px);
      color: $grey-4;
      text-align: center;

      &-white {
        @include font(14px);
        color: $white;
      }
    }

    &-subtitle {
      @include font-semi(12px);
      @include padding-two-var(0, 30px);
      color: $grey-4;
      text-align: center;
    }

    &-title {
      @include font-bold(20px);
      text-align: center;
      padding-top: 15px;

      @media (max-width: $max-mobile-width-sm) {
        @include font-bold(18px);
      }

      @media (max-height: 680px) {
        @include font-bold(16px);
      }

      &-small {
        @include font-semi(14px);
        padding-top: 10px;
      }
    }

    &-name {
      @include font-bold(20px);
      text-align: center;
      color: $purple-1;

      @media (max-width: $max-mobile-width-sm) {
        @include font-bold(18px);
      }

      @media (max-height: 680px) {
        @include font-bold(16px);
      }

      &-white {
        @include font-semi(24px);
        color: $white;
      }

      &-default {
        color: $white;
      }
    }

    &-button {
      @include purple-button();
      @include padding-two-var(10px);
      width: 100%;
      max-width: 265px;

      @media (max-width: $max-mobile-width-sm) {
        margin-bottom: 15px;
      }
    }
  }
  &-input {
    @extend %form-input;
    @include font(13px);
    @include margin-two-var(0, auto);
    display: block;
    outline: none;
    background-color: $dark-blue-7 !important;

    &-claim {
      pointer-events: none;
      opacity: 0.5;
    }

    &-enabled {
      pointer-events: all;
      opacity: 1;
    }
  }

  &-text {
    @include font-semi(13px);
    text-align: left;

    &-claim {
      @include font(14px);
      color: $light-purple;
      width: 100%;
      text-align: center;
    }

    &-wrapper {
      @include flex(space-between, center, row);
      width: 100%;

      @media (max-width: $max-mobile-width-sm) {
        @include flex(flex-start, center, row);
        gap: 5px;
      }
    }

    &-icon {
      @include flex(center, center, row);
      text-decoration: none;
      gap: 5px;
      cursor: pointer;
    }

    &-learn {
      @include font-semi(13px);
      color: $pink-1;
      white-space: pre;

      @media (max-width: $max-mobile-width-sm) {
        display: none;
      }
    }
  }

  &-icon {
    height: 10px;
    color: $pink-1;
  }

  &-button {
    @include purple-button();
    @include padding-two-var(10px);
    width: 100%;
    outline: none;
    border: none;

    &-claim {
      pointer-events: none;
      opacity: 0.5;
    }

    &-enabled {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.claim {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-container {
    width: 100%;
    max-width: 400px;
  }

  &-header {
    display: flex;

    &-back {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    &-title {
      @include font-semi(18px);
      text-align: center;
      width: 100%;
    }
  }

  &-body {
    @include flex(center, center, column);
    gap: 20px;

    &-banner {
      @include icon-image('banner', false, $stw-url);
      @include width-height(100%, 160px);
      @include margin-one-var(20px);
      background-size: cover;
    }


    &-title {
      @include font-semi(14px);
      text-align: center;
      width: 100%;
    }

    &-input {
      @extend %form-input;
      @include font(16px);
      @include margin-two-var(0, auto);
      display: block;
      outline: none;
    }

    &-button {
      @include purple-button();
      @include margin-two-var(0, auto);
      display: block;
      width: 100%;
      max-width: 400px;
    }

    &-mechanics {
      padding-top: 20px;

      &-title {
        @include font-bold(17px);
      }

      &-desc {
        @include font-semi(13px);
        padding-top: 20px;
        color: $grey-6;
      }
    }
  }
}