$font-family: 'Poppins', sans-serif !default;
$font-size: 12px !default;
$font-style: normal !default;

@mixin font-base($font-size, $font-family, $font-style) {
  font-size: $font-size;
  font-family: $font-family;
  font-style: $font-style;
  letter-spacing: 0.025em;
}

@mixin font-light($font-size: $font-size, $font-family: $font-family, $font-style: $font-style) {
  @include font-base($font-size, $font-family, $font-style);
  font-weight: 300;
}

@mixin font($font-size: $font-size, $font-family: $font-family, $font-style: $font-style) {
  @include font-base($font-size, $font-family, $font-style);
  font-weight: 400;
}

@mixin font-medium($font-size: $font-size, $font-family: $font-family, $font-style: $font-style) {
  @include font-base($font-size, $font-family, $font-style);
  font-weight: 500;
}

@mixin font-semi($font-size: $font-size, $font-family: $font-family, $font-style: $font-style) {
  @include font-base($font-size, $font-family, $font-style);
  font-weight: 600;
}

@mixin font-bold($font-size: $font-size, $font-family: $font-family, $font-style: $font-style) {
  @include font-base($font-size, $font-family, $font-style);
  font-weight: 700;
}

@mixin font-bolder($font-size: $font-size, $font-family: $font-family, $font-style: $font-style) {
  @include font-base($font-size, $font-family, $font-style);
  font-weight: 800;
}